import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root'
})
export class WorkService extends FirestoreService {

  constructor() {
    super();
    this.setCollectionRef('work');
  }
}
