import { Injector } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import {
  LoadingController, ToastController,
  AlertController, IonInfiniteScroll, IonRefresher, Platform, ModalController, NavController, ActionSheetController, PopoverController
} from '@ionic/angular';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Browser } from '@capacitor/browser';
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx';

import Swal, { SweetAlertIcon } from 'sweetalert2';
import { FirestoreService } from './firestore.service';

export abstract class BasePage extends FirestoreService {

  public isErrorViewVisible: boolean;
  public isEmptyViewVisible: boolean;
  public isContentViewVisible: boolean;
  public isLoadingViewVisible: boolean;

  protected refresher: IonRefresher;
  protected infiniteScroll: IonInfiniteScroll;
  protected router: Router;
  protected formBuilder: FormBuilder;
  protected modalCtrl: ModalController;
  protected popoverCtrl: PopoverController;
  protected actionSheetCtrl: ActionSheetController;
  protected activatedRoute: ActivatedRoute;
  private loader: any;
  private toastCtrl: ToastController;
  private loadingCtrl: LoadingController;
  private alertCtrl: AlertController;
  protected navCtrl: NavController;
  protected platform: Platform;
  private inAppBrowser: InAppBrowser;
  private safariViewController: SafariViewController;

  constructor(injector: Injector) {
    super();
    this.loadingCtrl = injector.get(LoadingController);
    this.actionSheetCtrl = injector.get(ActionSheetController);
    this.toastCtrl = injector.get(ToastController);
    this.alertCtrl = injector.get(AlertController);
    this.modalCtrl = injector.get(ModalController);
    this.popoverCtrl = injector.get(PopoverController);
    this.platform = injector.get(Platform);
    this.navCtrl = injector.get(NavController);
    this.formBuilder = injector.get(FormBuilder);

    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.inAppBrowser = injector.get(InAppBrowser);
    this.safariViewController = injector.get(SafariViewController);
  }

  public get isCapacitor(): boolean {
    return this.platform.is('capacitor');
  }

  public get isPwa(): boolean {
    return this.platform.is('pwa');
  }

  public get isDesktop(): boolean {
    return this.platform.is('desktop');
  }

  public get isMobile(): boolean {
    return this.platform.is('mobile');
  }

  public get isAndroid(): boolean {
    return this.platform.is('android');
  }

  public get isIos(): boolean {
    return this.platform.is('ios');
  }

  public isRead() {
    return this.platform.ready();
  }

  public get width(): number {
    return this.platform.width();
  }

  public get heigth(): number {
    return this.platform.height();
  }

  async showLoadingView(params: { showOverlay: boolean }) {

    if (params.showOverlay) {

      this.loader = await this.loadingCtrl.create({
        message: 'Carregando',
        backdropDismiss: true,
      });

      return await this.loader.present();

    } else {

      this.isErrorViewVisible = false;
      this.isEmptyViewVisible = false;
      this.isContentViewVisible = false;
      this.isLoadingViewVisible = true;
    }

    return true;

  }

  async dismissLoadingView() {

    if (!this.loader) { return; }

    return await this.loader.dismiss();
  }

  showContentView() {

    this.isErrorViewVisible = false;
    this.isEmptyViewVisible = false;
    this.isLoadingViewVisible = false;
    this.isContentViewVisible = true;

    this.dismissLoadingView();
  }

  showEmptyView() {

    this.isErrorViewVisible = false;
    this.isLoadingViewVisible = false;
    this.isContentViewVisible = false;
    this.isEmptyViewVisible = true;

    this.dismissLoadingView();
  }

  showErrorView() {

    this.isLoadingViewVisible = false;
    this.isContentViewVisible = false;
    this.isEmptyViewVisible = false;
    this.isErrorViewVisible = true;

    this.dismissLoadingView();
  }

  onRefreshComplete(data = null) {

    if (this.refresher) {
      this.refresher.disabled = true;
      this.refresher.complete();
      setTimeout(() => {
        this.refresher.disabled = false;
      }, 100);
    }

    if (this.infiniteScroll) {
      this.infiniteScroll.complete();

      if (data && data.length === 0) {
        this.infiniteScroll.disabled = true;
      } else {
        this.infiniteScroll.disabled = false;
      }
    }
  }

  async showToast(message: string, showCancel: boolean = false, duration: number = 3000, color = 'primary') {

    const options: any = {
      message,
      color,
      position: 'bottom',
      cssClass: 'tabs-bottom',
      duration,
    };
    if (showCancel) {
      options.buttons = [{
        text: 'Cancelar',
        role: 'cancel',
      }];
    }
    const toast = await this.toastCtrl.create(options);

    return await toast.present();
  }

  async showAlert(message: string, textBtn: string = 'Ok') {

    const alert = await this.alertCtrl.create({
      header: '',
      message,
      mode: 'ios',
      cssClass: 'alert-custom',
      buttons: [{
        text: textBtn,
        role: '',
      }]
    });
    await alert.present()
  }

  async showConfirm(message: string) {

    const confirm = await this.alertCtrl.create({
      header: '',
      message,
      buttons: [{
        text: 'Cancelar',
        role: 'cancel',
      }, {
        text: 'Ok',
        role: ''
      }]
    });

    return await confirm.present();
  }

  async showSweetConfirm(title: string, html: string, icon: SweetAlertIcon,
    confirmButtonText = 'Confirmar', cancelButtonText = 'Cancelar', customClass = {}) {
    return Swal.fire({
      title,
      html,
      icon,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText,
      cancelButtonColor: '#3085d6',
      cancelButtonText,
      heightAuto: false,
      allowOutsideClick: false,
      customClass
    });
  }

  async showSweetCustom(title: string, html: string, icon: SweetAlertIcon, confirmText) {
    return Swal.fire({
      title,
      text: html,
      icon,
      iconHtml: '<ion-icon name="location"></ion-icon>',
      confirmButtonText: confirmText,
      heightAuto: false
    });
  }

  async showSweet(html: string,title: string = '', icon: SweetAlertIcon = null) {
    return await Swal.fire({
      heightAuto: false,
      html,
      title,
      icon,
      confirmButtonText: 'OK'
    });
  }

  async openUrl(url: string) {

    if (!url) { return; }

    if (this.isCapacitor) {
      try {
        const isAvailable = false; //await this.safariViewController.isAvailable();

        if (isAvailable) {
          await this.safariViewController.show({
            url,
          }).toPromise();
        } else {
          Browser.open({url: url});
        }
      } catch (error) {
        console.log(error);
      }

    } else if (this.isPwa) {
      this.inAppBrowser.create(url, '_blank');
    } else {
      this.openSimpleUrl(url);
    }

  }

  openSimpleUrl(url: string) {
    this.inAppBrowser.create(url, '_system');
  }

  navigateTo(page: any, queryParams: any = {}) {
    this.router.navigate([page], { state: queryParams });
  }

  navigateToRelative(page: any, queryParams: any = null) {
    return this.router.navigate([page], {
      queryParams,
      relativeTo: this.activatedRoute,
      queryParamsHandling: queryParams ? 'merge' : '',
    });
  }

  createUrlTree(queryParams: any = {}) {
    return this.router.createUrlTree([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  goBack() {
    this.navCtrl.back();
  }

  setRoot(page: string) {
    this.navCtrl.navigateRoot(page);
  }

  getParams() {
    return this.activatedRoute.snapshot.params;
  }

  getQueryParams() {
    return this.activatedRoute.snapshot.queryParams;
  }

  async openModal(component, params = {}, className?) {
    const modal = await this.modalCtrl.create({
      component,
      cssClass: className,
      componentProps: {
        params,
      }
    });
    modal.present();
    return modal;
  }

  public setStringValue(valueVerify: number) {
    let value = valueVerify.toString(); 
    const indexStringValue = value.indexOf('.');  
    if (indexStringValue < 0) {
      value += '00'
    } else {
      const diff = ( value.length -1 ) - indexStringValue;
      if (diff === 1) {
        value += '0';
      }
    }
    return value
  }


}
