import { Injectable } from '@angular/core';

import { Auth, createUserWithEmailAndPassword, getAuth, GoogleAuthProvider, OAuthProvider, sendPasswordResetEmail, signInWithCredential, signInWithEmailAndPassword, UserCredential } from '@firebase/auth';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

import { SignInWithApple, SignInWithAppleResponse, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';

import { FirestoreService } from './firestore.service';
import { UsersService } from './users.service';

import { UserModel } from '../models/user-model';

import { MESSAGE_CONST } from 'src/app/constants/message.const';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends FirestoreService {

  protected auth: Auth;

  constructor(
    private userService: UsersService
  ) {
    super();
    this.auth = getAuth(FirestoreService.app);
    GoogleAuth.initialize({
      clientId: environment.clientId,
      scopes: ['profile', 'email'],
      grantOfflineAccess: true,
    });
  }

  async registerEmailAndPassword(user: UserModel) {
    const response = await createUserWithEmailAndPassword(this.auth, user.email, user.password)
      .then(async (response) => {
        user.id = response.user.uid;
        const responseData = await this.saveUserData(user);
        return { message: responseData, error: responseData ? false : true, messageButton: null, };
      })
      .catch((error) => {
        const errorFirebase = this.errorFirebase(error);
        return { ...errorFirebase, messageButton: null, error: true };
      })
    return response;
  }

  async registerUser(user: UserModel) {
    try {
      const responseData = await this.saveUserData(user);
      return { message: responseData, error: responseData ? false : true };
    } catch (error) {
      const errorFirebase = this.errorFirebase(error);
      return { ...errorFirebase, error: true };
    }
  }

  async saveUserData(user: UserModel) {
    const userData: UserModel = {
      id: user.id || null,
      idManage: user.idManage || null,
      image: user.image || null,
      dateRegister: new Date(),
      categoryMaterial: user?.categoryMaterial || [],
      checkedTerms: user.checkedTerms  || null,
      address: user.address  || null,
      birthDate: user.birthDate  || null,
      cnpj: user.cnpj  || null,
      enterpriseName: user.enterpriseName  || null,
      cpf: user.cpf || null,
      name: user.name || null,
      email: user.email || null,
      objective: user.objective || null,
      gender: user.gender || null,
      title: user.title || null,
      role: user.role || null,
      phone: user.phone || null,
      whatsapp: user.whatsapp || null,
      listDefault: user?.listDefault || false,
    }
    return await this.userService.addOrUpdateDocument(userData);
  }

  async loginEmailAndPassword(user: { email: string, password: string }) {
    const responseUser = await signInWithEmailAndPassword(this.auth, user.email, user.password)
      .then((response) => {
        return { user: response, message: MESSAGE_CONST.USER_FOUND, messageButton: null, error: false };
      })
      .catch((error) => {
        const errorFirebase = this.errorFirebase(error);
        const messageButton = null;
        return { ...errorFirebase, user: null, messageButton, error: true };
      });
    return responseUser;
  }

  async loginGoogle() {
    await GoogleAuth.signOut();
    const userResponse = await GoogleAuth.signIn();
    const credentialUser = GoogleAuthProvider.credential(userResponse.authentication?.idToken);
    return await signInWithCredential(this.auth, credentialUser);
  }

  async loginApple() {
    const options: SignInWithAppleOptions = {
      clientId: 'br.com.app.obra',
      redirectURI: environment.appleRedirect,
      scopes: 'email name'
    };

    const userResponse: SignInWithAppleResponse = await SignInWithApple.authorize(options);
    const provider = new OAuthProvider('apple.com');
    const credentialUser = provider.credential({
      idToken: userResponse.response.identityToken
    });

    const response: UserCredential =  await signInWithCredential(this.auth, credentialUser);
    const user: any = response.user;
    user.displayName = `${userResponse.response.givenName ?? ''} ${userResponse.response.familyName ?? ''}`;
    response.user = user;
    return response;
  }

  public async doLogout() {
    await this.auth.signOut();
  }

  async forgotPassword(email: string) {
    const responseRecovery = await sendPasswordResetEmail(this.auth, email).then((response) => {
      return { message: 'Usuário encontrado', code: null, error: false };
    })
      .catch((error) => {
        const errorFirebase = this.errorFirebase(error);
        return { ...errorFirebase, error: true };
      });
    return responseRecovery;
  }

  private errorFirebase(error: any) {
    let message: string;
    const code: string = error.code;
    switch (error.code) {
      case 'auth/invalid-email':
        message = MESSAGE_CONST.EMAIL_INVALID
        break;

      case 'auth/user-disabled':
        message = MESSAGE_CONST.USER_DISABLED
        break;

      case 'auth/user-not-found':
        message = MESSAGE_CONST.USER_NOT_DOUND
        break;

      case 'auth/wrong-password':
        message = MESSAGE_CONST.WRONG_PASSWORD
        break;

      case 'auth/too-many-requests':
        message = MESSAGE_CONST.TOO_MANY_REQUESTS
        break;

      case 'auth/email-already-in-use':
        message = MESSAGE_CONST.EMAIL_ALREADY_IN_USE
        break;

      default:
        message = MESSAGE_CONST.ERROR_HAS_OCCURRED
        break
    }

    return { message, code };
  }

  async getAccessToken() {
    return this.auth.currentUser?.getIdToken();
  }
}
