import { Injectable } from '@angular/core';

import { FirestoreService } from './firestore.service';

import { LogOperationsEnum, LogLocationsEnum } from '../enums/logs.enum';
import { LogModel } from '../models/log-model';

@Injectable({
  providedIn: 'root'
})
export class LogService extends FirestoreService {

  constructor() {
    super();
    this.setCollectionRef('log');
  }

  async createLog(operation: LogOperationsEnum, location: LogLocationsEnum, document: LogModel){
    this.setCollectionRef(`log/${location}/${operation}/`);
    return await this.addOrUpdateDocument(document);
  }
}
