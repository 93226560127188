import { BasePage } from 'src/app/services/base-page';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlossaryService } from 'src/app/services/glossary.service';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.page.html',
  styleUrls: ['./glossary.page.scss'],
})
export class GlossaryPage extends BasePage implements OnInit {

  @Input() params: string;
  public selectedContent: any = {};
  public contents;

  private typeTitles: { [id: string]: string } = {
    Qualidade: 'quality',
    Custo: 'cost',
    Prazo: 'deadline',
    Modelo: 'taskModel',
    saveWork: 'saveWork',
  }

  constructor(private injector: Injector, public modalCtrl: ModalController, public glossarySrvc: GlossaryService) {
    super(injector);
  }

  async ngOnInit() {
   this.contents = await this.glossarySrvc.load({ where: [{ key: 'type', condition: '==', value: this.typeTitles[this.params] }] }).then((response) => {
      if(response[0]){
        this.selectedContent = response[0];
      } else {
        this.selectedContent = {
          id: '',
          text: '',
        }
      }
    });
  }

  public closeModal(): void {
    this.modalCtrl.dismiss();
  }

}
