export const APP_ROUTES = {
    START: 'start',
    ONBOARDING: 'onboarding',
    REGISTER: 'register',
    RECOVERY: 'recovery',
    MEMBERSHIP: 'membership',
    LOGIN: 'login',
    MENU: 'menu',

    MAIN: 'main',
    HOME: 'home',
    GLOSSARY: 'glossary',
    SEARCH: 'search',
    NOTIFICATIONS: 'notifications',
    PROFILE: 'profile',
    PORTAL_PROVIDER: 'portal-provider',

    REGISTERS: 'registers',
    CLIENTS:'clients',
    MODELS:'models',
    MODEL_DETAILS: 'model-details',
    BANK_ACCOUNTS:'bank-accounts',
    MATERIALS:'materials',
    TEAM:'team',
    SUPPLIERS:'suppliers',
    ADD_ATTACHMENT: 'add-attachment',
    ADD_FILTER:'add-filter',
    ADD_REGISTER:'add-register',
    PROFILE_DETAILS: 'profile-details',
    MY_BUDGET: 'my-budget',

    WORK: 'work',
    WORKDETAIL: 'work-detail',
    ADD_WORK: 'add-work',
    TASKS: 'tasks',
    TASKSDETAILS: 'tasks-details',
    TASKGRAPHIC: 'tasks-graphic',
    
    FINANCIAL: 'financial',
    BILLS: 'bills',
    BANK_RECONCILIATION: 'bank-reconciliation',
    STATEMENT: 'statement',
    CASH_FLOW: 'cash-flow',
    
    CONTACTS: 'contacts',
    SETTINGS: 'settings',
    REPORTS: 'reports',
    ABOUT: 'about',
    HELP: 'help',
    PRIVACY: 'privacy-policy',
    
    SHOP: 'shop',
    QUOTATION: 'quotation'

  };
