export enum LogOperationsEnum {
  Created = 'created',
  Updated = 'updated',
  Deleted = 'deleted',
};

export enum LogLocationsEnum {
  Work = 'work',
  User = 'user',
  Task = 'task',
  Bill = 'bill',
  Comment = 'comment'
};