import { UserModel } from './../../models/user-model';
import { APP_ROUTES } from 'src/app/constants/routes.const';
import { StageModels } from 'src/app/models/models-model';
import { Component, Injector, Input, OnChanges } from '@angular/core';

// SERVICES
import { BasePage } from 'src/app/services/base-page';

// MODELS
import { WorkModel } from './../../models/work-model';
import { GraphicModel } from 'src/app/models/graphic-model';

// PAGES
import { GlossaryPage } from 'src/app/modules/main/home/glossary/glossary.page';
import * as moment from 'moment';

@Component({
  selector: 'app-graphics-view',
  templateUrl: './graphics-view.page.html',
  styleUrls: ['./graphics-view.page.scss'],
})
export class GraphicsViewPage extends BasePage implements OnChanges {

  @Input() inputObj: WorkModel | StageModels;
  @Input() userData: UserModel;
  @Input() showButtonsView: boolean;
  @Input() taskGraphic: boolean = false;
  @Input() allWorks: boolean;
  @Input() block: boolean = false;

  slideOpts = {
    slidesPerView: 1.1,
    spaceBetween: 15,
  };

  public charts: GraphicModel[] = [
    {
      title: 'Custo',
      typeGraphic: 'cost',
      single: [
        {
          "name": "Orçado",
          "value": "0",
          "extras": "0",
        },
        {
          "name": "Proporcional",
          "value": "0",
          "extras": "0",
        },
        {
          "name": "Comprometido",
          "value": "0",
          "extras": "0",
        },
      ],
      value: [
        {
          "name": "Orçado",
          "value": "0",
          "extras": "0",
        },
        {
          "name": "Proporcional",
          "value": "0",
          "extras": "0",
        },
        {
          "name": "Comprometido",
          "value": "0",
          "extras": "0",
        },
      ],
      view: [450, 350],
      max: 295,
      bigSegment: 7,
      smallSegment: 7,
      legend: false,
      text: false,
      colorScheme: {
        domain: ['#333333', '#99BB14', '#0081C7']
      },
    },
    {
      title: 'Prazo',
      typeGraphic: 'time',
      single: [
        {
          "name": "Término",
          "value": "0",
          "extras": "0",
        },
        {
          "name": "Proporcional",
          "value": "0",
          "extras": "0",
        },
        {
          "name": "Realizado",
          "value": "0",
          "extras": "0",
        },
      ],
      value: [
        {
          "name": "Término",
          "value": "0",
          "extras": "0",
        },
        {
          "name": "Proporcional",
          "value": "0",
          "extras": "0",
        },
        {
          "name": "Realizado",
          "value": "0",
          "extras": "0",
        },

      ],
      max: 208,
      bigSegment: 9,
      smallSegment: 7,
      view: [450, 350],
      legend: false,
      text: false,
      colorScheme: {
        domain: ['#333333', '#99BB14', '#0081C7']
      },
    },
    {
      title: 'Qualidade',
      typeGraphic: 'quality',
      single: [
        {
          "name": "Total",
          "value": "0",
          "extras": "0",
        },
        {
          "name": "Em aberto",
          "value": "0",
          "extras": "0",
        },
        {
          "name": "Concluídas",
          "value": "0",
          "extras": "0",
        },
      ],
      value:
        [
          {
            "name": "Total",
            "value": "0",
            "extras": "0",
          },
          {
            "name": "Em aberto",
            "value": "0",
            "extras": "0",
          },
          {
            "name": "Concluídas",
            "value": "0",
            "extras": "0",
          },
        ],
      max: 10,
      bigSegment: 5,
      smallSegment: 4,
      view: [450, 350],
      legend: false,
      text: false,
      colorScheme: {
        domain: ['#333333', '#99BB14', '#0081C7']
      },
    }
  ];

  public chartsEdit: GraphicModel[] = [];

  public showPercentage: boolean = false;
  public loadingGraphic: boolean = true;

  public showMonetary: number = 0;
  public showDays: number = 1;

  constructor(
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnChanges() {
    this.loadingGraphic = true;
    this.chartsEdit = [...this.charts];

    if (this.inputObj && this.inputObj?.graphics) {

      for (let chart of this.chartsEdit) {
        chart.max = parseInt(this.inputObj?.graphics[chart.typeGraphic][0]?.value);

        for (let i = 0; i < 3; i++) {
          const graphicData = this.inputObj.graphics[chart.typeGraphic];
          const value = graphicData ? parseInt(graphicData[i].value) : 0;
          const extras = graphicData ? graphicData[i].extras : undefined;

          if (chart.typeGraphic === 'time' && !this.allWorks)
            chart = this.populate(chart, this.verifyIndexTime(i), i);
          else if (chart.typeGraphic === 'cost' && i === 1 && !this.allWorks)
            chart = this.populate(chart, this.calcCostProportional(), i);
          else
            chart = this.populate(chart, value, i, extras);
        }
      }

      if (this.userData.role.includes('client') || this.taskGraphic) {
        if (this.inputObj.hideCost) {
          this.chartsEdit.splice(0, 1);
          this.showMonetary = null;
          this.showDays = 0;
        }
        if (this.inputObj.hideTime) {
          this.chartsEdit.splice(this.chartsEdit.map(e => e.title).indexOf('Prazo'), 1);
          this.showDays = null;
        }
        if (this.inputObj.hideQuality) {
          this.chartsEdit.splice(this.chartsEdit.map(e => e.title).indexOf('Qualidade'), 1);
        }
      }
    }
    setTimeout(() => {
      this.loadingGraphic = false;
    }, 100);
  }

  public populate(chart: GraphicModel, value: any, index: number, extras?: any): GraphicModel {
    value = this.roundNumber(value);
    extras = extras ? this.roundNumber(extras) : undefined;

    chart.single[index].value = value;
    chart.value[index].value = value;
    chart.single[index].extras = extras ? extras : value;
    chart.value[index].extras = extras ? extras : value;

    return chart;
  }

  public roundNumber(number: string | number): number {
    if (typeof number == 'string') number = Number(number);

    return Math.round((number * 100) * (1 + Number.EPSILON)) / 100;
  }

  public replaceValueNumber(value: string) {
    if (typeof value === 'string') {
      return parseFloat(value.replace(',', '.')) || 0;
    } else {
      return value;
    }
  }

  public calculatePercentage(info: any, value: any) {
    if (this.replaceValueNumber(info.value[0].value) == 0) {
      return 0;
    }
    return (this.replaceValueNumber(value) * 100 / this.replaceValueNumber(info.value[0].value)) / 100 || 0;
  }

  async openGlossary(title) {
    await this.openModal(GlossaryPage, title, 'glossary-modal')
  }

  public async openTaskGraphicPage(index: number) {
    const typeGraphicInfo = index === 0 ? 'cost' : index === 1 ? 'time' : 'quality'
    this.navigateTo(`${APP_ROUTES.MAIN}/${APP_ROUTES.TASKGRAPHIC}/${this.inputObj.id}`, { type: typeGraphicInfo, block: this.block });
  }

  private calcCostProportional() {
    return (this.inputObj.graphics['cost'][0].value / 100) * this.inputObj.progress;
  }

  private verifyIndexTime(index: number) {
    switch (index) {
      case 0:
        return this.calculateDuration();
      case 1:
        return this.calculateDays();
      case 2:
        return this.calculateProgress();
    }
  }

  public calculateDuration() {
    if (this.inputObj && this.inputObj?.graphics) {
      const diff = new Date(this.inputObj.endDate).getTime() - new Date(typeof this.inputObj.startDate != 'string' ? this.inputObj.startDate.toDate() : this.inputObj.startDate).getTime();
      const resultDiff = Math.trunc(diff / 86400000) + 1;
      return resultDiff;
      //return resultDiff > 1 ? resultDiff + ' dias' : resultDiff + 'dia';
    }
    return '';
  }

  public calculateDays() {
    if (this.inputObj && this.inputObj?.graphics) {
      return moment(new Date).startOf('day').diff(moment(typeof this.inputObj.startDate != 'string' ? this.inputObj.startDate.toDate() : this.inputObj.startDate).startOf('day'), 'days');
    }
    return '';
  }

  public calculateProgress() {
    if (this.inputObj && this.inputObj?.graphics) {
      const duration = this.calculateDuration();
      return (((duration || 0) / 100) * this.inputObj.progress).toFixed(2);
    }
    return '';
  }

  compareValueCC() {
    if (this.inputObj) {
      const valueDiff = this.chartsEdit[0].single[2].value - this.chartsEdit[0].single[1].value;
      if (valueDiff > 0) {
        this.chartsEdit[0].colorScheme.domain[2] = '#D02541';
        return false;
      } else {
        return true;
      }
    }
  }

  compareValueCost(indexGraphic) {
    if (this.inputObj) {
      if (indexGraphic == 1 || indexGraphic == 2) {
        const valueDiff = this.chartsEdit[indexGraphic].single[2].value - this.chartsEdit[indexGraphic].single[1].value;
        if (valueDiff < 0) {
          this.chartsEdit[indexGraphic].colorScheme.domain[2] = '#D02541';
          return false;
        } else {
          this.chartsEdit[indexGraphic].colorScheme.domain[2] = '#0081C7';
          return true;
        }
      } else {
        const valueDiff = this.chartsEdit[indexGraphic].single[2].value - this.chartsEdit[indexGraphic].single[1].value;
        if (valueDiff < 0) {
          return true;
        } else {
          this.chartsEdit[indexGraphic].colorScheme.domain[2] = '#D02541';
          return false;
        }
      }

    }
  }

}