export const pt = {
  "Yes": "Sim",
  "No": "Não",
  "Cancel": "Cancelar",
  "Close": "Fechar",
  "Clear": "Limpar",
  "Done": "Concluído",
  "Loading": "Carregando ...",
  "Select": "Selecione ...",
  "Search": "Pesquisar ...",
  "Back": "Voltar",
  "OK": "OK",

  "dxCollectionWidget-noDataText": "Sem dados",

  "dxDropDownEditor-selectLabel": "Selecione",

  "validation-required": "Preenchimento obrigatório",
  "validation-required-formatted": "{0} é de preenchimento obrigatório",
  "validation-numeric": "Valor deve ser um número",
  "validation-numeric-formatted": "{0} deve ser um número",
  "validation-range": "Valor está fora do intervalo",
  "validation-range-formatted": "{0} está fora do intervalo",
  "validation-stringLength": "O comprimento do valor não está correto",
  "validation-stringLength-formatted": "O comprimento de {0} não está correto",
  "validation-custom": "Valor inválido",
  "validation-custom-formatted": "{0} é inválido",
  "validation-async": "Valor inválido",
  "validation-async-formatted": "{0} é inválido",
  "validation-compare": "Valores não coincidem",
  "validation-compare-formatted": "{0} não coincidem",
  "validation-pattern": "Valor não corresponde ao padrão",
  "validation-pattern-formatted": "{0} não corresponde ao padrão",
  "validation-email": "Email inválido",
  "validation-email-formatted": "{0} é inválido",
  "validation-mask": "Valor inválido",

  "dxLookup-searchPlaceholder": "Número mínimo de caracteres: {0}",

  "dxList-pullingDownText": "Puxar para baixo para recarregar...",
  "dxList-pulledDownText": "Soltar para recarregar...",
  "dxList-refreshingText": "Recarregando ...",
  "dxList-pageLoadingText": "A carregar ...",
  "dxList-nextButtonText": "Mais",
  "dxList-selectAll": "Selecionar todos",
  "dxListEditDecorator-delete": "Eliminar",
  "dxListEditDecorator-more": "Mais",

  "dxScrollView-pullingDownText": "Puxar para baixo para recarregar...",
  "dxScrollView-pulledDownText": "Soltar para recarregar...",
  "dxScrollView-refreshingText": "Recarregando ...",
  "dxScrollView-reachBottomText": "A carregar ...",

  "dxDateBox-simulatedDataPickerTitleTime": "Selecionar hora",
  "dxDateBox-simulatedDataPickerTitleDate": "Selecionar data",
  "dxDateBox-simulatedDataPickerTitleDateTime": "Selecionar data e hora",
  "dxDateBox-validation-datetime": "Valor deve ser uma data ou hora",

  "dxFileUploader-selectFile": "Selecionar arquivo",
  "dxFileUploader-dropFile": "ou Soltar arquivo aqui",
  "dxFileUploader-bytes": "bytes",
  "dxFileUploader-kb": "kb",
  "dxFileUploader-Mb": "Mb",
  "dxFileUploader-Gb": "Gb",
  "dxFileUploader-upload": "Upload",
  "dxFileUploader-uploaded": "Upload concluído",
  "dxFileUploader-readyToUpload": "Pronto para upload",
  "dxFileUploader-uploadAbortedMessage": "Upload cancelled",
  "dxFileUploader-uploadFailedMessage": "Upload falhou",
  "dxFileUploader-invalidFileExtension": "Tipo de arquivo não é permitido",
  "dxFileUploader-invalidMaxFileSize": "O arquivo é muito grande",
  "dxFileUploader-invalidMinFileSize": "O arquivo é muito pequeno",

  "dxRangeSlider-ariaFrom": "De {0}",
  "dxRangeSlider-ariaTill": "Até {0}",
  "dxSwitch-switchedOnText": "LIGADO",
  "dxSwitch-switchedOffText": "DESLIGADO",

  "dxForm-optionalMark": "opcional",
  "dxForm-requiredMessage": "{0} é de preenchimento obrigatório",

  "dxNumberBox-invalidValueMessage": "Valor deve ser um número",
  "dxNumberBox-noDataText": "Sem dados",

  "dxDataGrid-columnChooserTitle": "Seletor de Colunas",
  "dxDataGrid-columnChooserEmptyText": "Arraste uma coluna para até aqui para escondê-la",
  "dxDataGrid-groupContinuesMessage": "Continua na página seguinte",
  "dxDataGrid-groupContinuedMessage": "Continuação da página anterior",
  "dxDataGrid-groupHeaderText": "Agrupar pela coluna",
  "dxDataGrid-ungroupHeaderText": "Remover grupo",
  "dxDataGrid-ungroupAllText": "Remover todos os grupos",
  "dxDataGrid-editingEditRow": "Editar",
  "dxDataGrid-editingSaveRowChanges": "Salvar",
  "dxDataGrid-editingCancelRowChanges": "Cancelar",
  "dxDataGrid-editingDeleteRow": "Eliminar",
  "dxDataGrid-editingUndeleteRow": "Recuperar",
  "dxDataGrid-editingConfirmDeleteMessage": "Tem certeza que deseja eliminar este registro?",
  "dxDataGrid-validationCancelChanges": "Cancelar alterações",
  "dxDataGrid-groupPanelEmptyText": "Arrastar o cabeçalho de uma coluna para aqui para agrupar por essa coluna",
  "dxDataGrid-noDataText": "Sem dados",
  "dxDataGrid-searchPanelPlaceholder": "Pesquisar ...",
  "dxDataGrid-filterRowShowAllText": "(Todos)",
  "dxDataGrid-filterRowResetOperationText": "Limpar",
  "dxDataGrid-filterRowOperationEquals": "Igual",
  "dxDataGrid-filterRowOperationNotEquals": "Diferente",
  "dxDataGrid-filterRowOperationLess": "Menor que",
  "dxDataGrid-filterRowOperationLessOrEquals": "Menor que ou igual a",
  "dxDataGrid-filterRowOperationGreater": "Maior que",
  "dxDataGrid-filterRowOperationGreaterOrEquals": "Maior que ou igual a",
  "dxDataGrid-filterRowOperationStartsWith": "Começa com",
  "dxDataGrid-filterRowOperationContains": "Contém",
  "dxDataGrid-filterRowOperationNotContains": "Não contém",
  "dxDataGrid-filterRowOperationEndsWith": "Termina com",
  "dxDataGrid-filterRowOperationBetween": "Entre",
  "dxDataGrid-filterRowOperationBetweenStartText": "Início",
  "dxDataGrid-filterRowOperationBetweenEndText": "Fim",
  "dxDataGrid-applyFilterText": "Aplicar filtro",
  "dxDataGrid-trueText": "verdadeiro",
  "dxDataGrid-falseText": "falso",
  "dxDataGrid-sortingAscendingText": "Ordenar ascendentemente",
  "dxDataGrid-sortingDescendingText": "Ordenar descendentemente",
  "dxDataGrid-sortingClearText": "Limpar ordenação",
  "dxDataGrid-editingSaveAllChanges": "Salvar todas as alterações",
  "dxDataGrid-editingCancelAllChanges": "Descartar alterações",
  "dxDataGrid-editingAddRow": "Adicionar uma linha",
  "dxDataGrid-summaryMin": "Mín: {0}",
  "dxDataGrid-summaryMinOtherColumn": "Mín de {1} é {0}",
  "dxDataGrid-summaryMax": "Máx: {0}",
  "dxDataGrid-summaryMaxOtherColumn": "Máx de {1} é {0}",
  "dxDataGrid-summaryAvg": "Méd: {0}",
  "dxDataGrid-summaryAvgOtherColumn": "Média de {1} é {0}",
  "dxDataGrid-summarySum": "Soma: {0}",
  "dxDataGrid-summarySumOtherColumn": "Soma de {1} é {0}",
  "dxDataGrid-summaryCount": "Contagem: {0}",
  "dxDataGrid-columnFixingFix": "Fixar",
  "dxDataGrid-columnFixingUnfix": "Não fixar",
  "dxDataGrid-columnFixingLeftPosition": "À esquerda",
  "dxDataGrid-columnFixingRightPosition": "À direita",
  "dxDataGrid-exportTo": "Exportar para",
  "dxDataGrid-exportToExcel": "Exportar para Excel",
  "dxDataGrid-exporting": "Exportar...",
  "dxDataGrid-excelFormat": "Planilha Excel",
  "dxDataGrid-selectedRows": "Linhas selecionadas",
  "dxDataGrid-exportSelectedRows": "Exportar linhas selecionadas",
  "dxDataGrid-exportAll": "Exportar todos os dados",
  "dxDataGrid-headerFilterLabel": "Filter options",
  "dxDataGrid-headerFilterIndicatorLabel": "Show filter options for column '{0}'",
  "dxDataGrid-headerFilterEmptyValue": "(Vazio)",
  "dxDataGrid-headerFilterOK": "OK",
  "dxDataGrid-headerFilterCancel": "Cancelar",
  "dxDataGrid-ariaAdaptiveCollapse": "Hide additional data",
  "dxDataGrid-ariaAdaptiveExpand": "Display additional data",
  "dxDataGrid-ariaColumn": "Coluna",
  "dxDataGrid-ariaValue": "Valor",
  "dxDataGrid-ariaFilterCell": "Filtro de célula",
  "dxDataGrid-ariaCollapse": "Contrair",
  "dxDataGrid-ariaExpand": "Expandir",
  "dxDataGrid-ariaDataGrid": "Grelha de dados",
  "dxDataGrid-ariaSearchInGrid": "Pesquisar na grade de dados",
  "dxDataGrid-ariaSelectAll": "Selecionar todos",
  "dxDataGrid-ariaSelectRow": "Selecionar linha",
  "dxDataGrid-ariaToolbar": "Data grid toolbar",
  "dxDataGrid-filterBuilderPopupTitle": "Construtor de filtro",
  "dxDataGrid-filterPanelCreateFilter": "Criar filtro",
  "dxDataGrid-filterPanelClearFilter": "Limpar",
  "dxDataGrid-filterPanelFilterEnabledHint": "Habilitar o filtro",

  "dxTreeList-ariaTreeList": "Lista em árvore",
  "dxTreeList-ariaSearchInGrid": "Search in the tree list",
  "dxTreeList-ariaToolbar": "Tree list toolbar",
  "dxTreeList-editingAddRowToNode": "Adicionar",

  "dxPager-infoText": "Página {0} de {1} ({2} itens)",
  "dxPager-pagesCountText": "de",
  "dxPager-pageSizesAllText": "Todos",
  "dxPager-page": "Page {0}",
  "dxPager-prevPage": "Previous Page",
  "dxPager-nextPage": "Next Page",
  "dxPager-ariaLabel": "Page Navigation",

  "dxPivotGrid-grandTotal": "Grande Total",
  "dxPivotGrid-total": "{0} Total",
  "dxPivotGrid-fieldChooserTitle": "Seletor de Colunas",
  "dxPivotGrid-showFieldChooser": "Mostrar Seletor de Colunas",
  "dxPivotGrid-expandAll": "Expandir Tudo",
  "dxPivotGrid-collapseAll": "Contrair Tudo",
  "dxPivotGrid-sortColumnBySummary": "Ordenar \"{0}\" por esta Coluna",
  "dxPivotGrid-sortRowBySummary": "Ordenar \"{0}\" por esta Linha",
  "dxPivotGrid-removeAllSorting": "Remover Todas as Ordenações",
  "dxPivotGrid-dataNotAvailable": "N/A",
  "dxPivotGrid-rowFields": "Campos de Linha",
  "dxPivotGrid-columnFields": "Campos de Coluna",
  "dxPivotGrid-dataFields": "Campos de Dados",
  "dxPivotGrid-filterFields": "Campos de Filtro",
  "dxPivotGrid-allFields": "Todos os Campos",
  "dxPivotGrid-columnFieldArea": "Arraste os campos de coluna até aqui",
  "dxPivotGrid-dataFieldArea": "Arraste os campos de dados até aqui",
  "dxPivotGrid-rowFieldArea": "Arraste os campos de linha até aqui",
  "dxPivotGrid-filterFieldArea": "Arraste os campos de filtro até aqui",

  "dxScheduler-editorLabelTitle": "Assunto",
  "dxScheduler-editorLabelStartDate": "Data de Início",
  "dxScheduler-editorLabelEndDate": "Data Final",
  "dxScheduler-editorLabelDescription": "Descrição",
  "dxScheduler-editorLabelRecurrence": "Repetir",

  "dxScheduler-openAppointment": "Abrir compromisso",

  "dxScheduler-recurrenceNever": "Nunca",
  "dxScheduler-recurrenceMinutely": "Minutely",
  "dxScheduler-recurrenceHourly": "Hourly",
  "dxScheduler-recurrenceDaily": "Diariamente",
  "dxScheduler-recurrenceWeekly": "Semanalmente",
  "dxScheduler-recurrenceMonthly": "Mensalmente",
  "dxScheduler-recurrenceYearly": "Anualmente",

  "dxScheduler-recurrenceRepeatEvery": "Todos",
  "dxScheduler-recurrenceRepeatOn": "Repeat On",
  "dxScheduler-recurrenceEnd": "Fim da repetição",
  "dxScheduler-recurrenceAfter": "Depois de",
  "dxScheduler-recurrenceOn": "A",

  "dxScheduler-recurrenceRepeatMinutely": "minute(s)",
  "dxScheduler-recurrenceRepeatHourly": "hour(s)",
  "dxScheduler-recurrenceRepeatDaily": "dia(s)",
  "dxScheduler-recurrenceRepeatWeekly": "semana(s)",
  "dxScheduler-recurrenceRepeatMonthly": "mês(es)",
  "dxScheduler-recurrenceRepeatYearly": "ano(s)",

  "dxScheduler-switcherDay": "Dia",
  "dxScheduler-switcherWeek": "Semana",
  "dxScheduler-switcherWorkWeek": "Dias úteis",
  "dxScheduler-switcherMonth": "Mês",

  "dxScheduler-switcherAgenda": "Agenda",

  "dxScheduler-switcherTimelineDay": "Linha de tempo Dia",
  "dxScheduler-switcherTimelineWeek": "Linha de tempo Semana",
  "dxScheduler-switcherTimelineWorkWeek": "Linha de tempo Dias úteis",
  "dxScheduler-switcherTimelineMonth": "Linha de tempo Mês",

  "dxScheduler-recurrenceRepeatOnDate": "na data",
  "dxScheduler-recurrenceRepeatCount": "ocorrência(s)",
  "dxScheduler-allDay": "Todo o dia",

  "dxScheduler-confirmRecurrenceEditMessage": "Deseja editar só este compromisso ou a série toda?",
  "dxScheduler-confirmRecurrenceDeleteMessage": "Deseja eliminar só este compromisso ou a série toda?",

  "dxScheduler-confirmRecurrenceEditSeries": "Editar série",
  "dxScheduler-confirmRecurrenceDeleteSeries": "Eliminar série",
  "dxScheduler-confirmRecurrenceEditOccurrence": "Editar compromisso",
  "dxScheduler-confirmRecurrenceDeleteOccurrence": "Eliminar compromisso",

  "dxScheduler-noTimezoneTitle": "Sem fuso horário",
  "dxScheduler-moreAppointments": "{0} mais",

  "dxCalendar-todayButtonText": "Hoje",
  "dxCalendar-ariaWidgetName": "Calendário",

  "dxColorView-ariaRed": "Vermelho",
  "dxColorView-ariaGreen": "Verde",
  "dxColorView-ariaBlue": "Azul",
  "dxColorView-ariaAlpha": "Transparência",
  "dxColorView-ariaHex": "Código de cor",

  "dxTagBox-selected": "{0} selecionados",
  "dxTagBox-allSelected": "Todos selecionados ({0})",
  "dxTagBox-moreSelected": "{0} mais",

  "vizExport-printingButtonText": "Imprimir",
  "vizExport-titleMenuText": "Exportar/Imprimir",
  "vizExport-exportButtonText": "{0}-Arquivo",

  "dxFilterBuilder-and": "E",
  "dxFilterBuilder-or": "OU",
  "dxFilterBuilder-notAnd": "NÃO E",
  "dxFilterBuilder-notOr": "NÃO OU",
  "dxFilterBuilder-addCondition": "Adicionar condição",
  "dxFilterBuilder-addGroup": "Adicionar Grupo",
  "dxFilterBuilder-enterValueText": "<preencha com um valor>",
  "dxFilterBuilder-filterOperationEquals": "Igual",
  "dxFilterBuilder-filterOperationNotEquals": "Diferente",
  "dxFilterBuilder-filterOperationLess": "Menor que",
  "dxFilterBuilder-filterOperationLessOrEquals": "Menor ou igual que",
  "dxFilterBuilder-filterOperationGreater": "Maior que",
  "dxFilterBuilder-filterOperationGreaterOrEquals": "Maior ou igual que",
  "dxFilterBuilder-filterOperationStartsWith": "Começa com",
  "dxFilterBuilder-filterOperationContains": "Contém",
  "dxFilterBuilder-filterOperationNotContains": "Não contém",
  "dxFilterBuilder-filterOperationEndsWith": "Termina com",
  "dxFilterBuilder-filterOperationIsBlank": "É vazio",
  "dxFilterBuilder-filterOperationIsNotBlank": "Não é vazio",
  "dxFilterBuilder-filterOperationBetween": "Entre",
  "dxFilterBuilder-filterOperationAnyOf": "Algum de",
  "dxFilterBuilder-filterOperationNoneOf": "Nenhum de",

  "dxHtmlEditor-dialogColorCaption": "Alterar cor da fonte",
  "dxHtmlEditor-dialogBackgroundCaption": "Alterar cor de plano de fundo",
  "dxHtmlEditor-dialogLinkCaption": "Adicionar link",
  "dxHtmlEditor-dialogLinkUrlField": "URL",
  "dxHtmlEditor-dialogLinkTextField": "Texto",
  "dxHtmlEditor-dialogLinkTargetField": "Abrir link em nova janela",
  "dxHtmlEditor-dialogImageCaption": "Adicionar imagem",
  "dxHtmlEditor-dialogImageUrlField": "URL",
  "dxHtmlEditor-dialogImageAltField": "Texto alternativo",
  "dxHtmlEditor-dialogImageWidthField": "Largura (px)",
  "dxHtmlEditor-dialogImageHeightField": "Altura (px)",
  "dxHtmlEditor-dialogInsertTableRowsField": "Rows",
  "dxHtmlEditor-dialogInsertTableColumnsField": "Columns",
  "dxHtmlEditor-dialogInsertTableCaption": "Insert Table",
  "dxHtmlEditor-dialogUpdateImageCaption": "Update Image",
  "dxHtmlEditor-dialogImageUpdateButton": "Update",
  "dxHtmlEditor-dialogImageAddButton": "Add",
  "dxHtmlEditor-dialogImageSpecifyUrl": "From the Web",
  "dxHtmlEditor-dialogImageSelectFile": "From This Device",
  "dxHtmlEditor-dialogImageKeepAspectRatio": "Keep Aspect Ratio",
  "dxHtmlEditor-dialogImageEncodeToBase64": "Encode to Base64",
  "dxHtmlEditor-heading": "Cabeçalho",
  "dxHtmlEditor-normalText": "Texto normal",
  "dxHtmlEditor-background": "Background Color",
  "dxHtmlEditor-bold": "Bold",
  "dxHtmlEditor-color": "Font Color",
  "dxHtmlEditor-font": "Font",
  "dxHtmlEditor-italic": "Italic",
  "dxHtmlEditor-link": "Add Link",
  "dxHtmlEditor-image": "Add Image",
  "dxHtmlEditor-size": "Size",
  "dxHtmlEditor-strike": "Strikethrough",
  "dxHtmlEditor-subscript": "Subscript",
  "dxHtmlEditor-superscript": "Superscript",
  "dxHtmlEditor-underline": "Underline",
  "dxHtmlEditor-blockquote": "Blockquote",
  "dxHtmlEditor-header": "Header",
  "dxHtmlEditor-increaseIndent": "Increase Indent",
  "dxHtmlEditor-decreaseIndent": "Decrease Indent",
  "dxHtmlEditor-orderedList": "Ordered List",
  "dxHtmlEditor-bulletList": "Bullet List",
  "dxHtmlEditor-alignLeft": "Align Left",
  "dxHtmlEditor-alignCenter": "Align Center",
  "dxHtmlEditor-alignRight": "Align Right",
  "dxHtmlEditor-alignJustify": "Align Justify",
  "dxHtmlEditor-codeBlock": "Code Block",
  "dxHtmlEditor-variable": "Add Variable",
  "dxHtmlEditor-undo": "Undo",
  "dxHtmlEditor-redo": "Redo",
  "dxHtmlEditor-clear": "Clear Formatting",
  "dxHtmlEditor-insertTable": "Insert Table",
  "dxHtmlEditor-insertHeaderRow": "Insert Header Row",
  "dxHtmlEditor-insertRowAbove": "Insert Row Above",
  "dxHtmlEditor-insertRowBelow": "Insert Row Below",
  "dxHtmlEditor-insertColumnLeft": "Insert Column Left",
  "dxHtmlEditor-insertColumnRight": "Insert Column Right",
  "dxHtmlEditor-deleteColumn": "Delete Column",
  "dxHtmlEditor-deleteRow": "Delete Row",
  "dxHtmlEditor-deleteTable": "Delete Table",
  "dxHtmlEditor-cellProperties": "Cell Properties",
  "dxHtmlEditor-tableProperties": "Table Properties",
  "dxHtmlEditor-insert": "Insert",
  "dxHtmlEditor-delete": "Delete",
  "dxHtmlEditor-border": "Border",
  "dxHtmlEditor-style": "Style",
  "dxHtmlEditor-width": "Width",
  "dxHtmlEditor-height": "Height",
  "dxHtmlEditor-borderColor": "Color",
  "dxHtmlEditor-tableBackground": "Background",
  "dxHtmlEditor-dimensions": "Dimensions",
  "dxHtmlEditor-alignment": "Alignment",
  "dxHtmlEditor-horizontal": "Horizontal",
  "dxHtmlEditor-vertical": "Vertical",
  "dxHtmlEditor-paddingVertical": "Vertical Padding",
  "dxHtmlEditor-paddingHorizontal": "Horizontal Padding",
  "dxHtmlEditor-pixels": "Pixels",
  "dxHtmlEditor-list": "List",
  "dxHtmlEditor-ordered": "Ordered",
  "dxHtmlEditor-bullet": "Bullet",
  "dxHtmlEditor-align": "Align",
  "dxHtmlEditor-center": "Center",
  "dxHtmlEditor-left": "Left",
  "dxHtmlEditor-right": "Right",
  "dxHtmlEditor-indent": "Indent",
  "dxHtmlEditor-justify": "Justify",

  "dxFileManager-newDirectoryName": "Untitled directory",
  "dxFileManager-rootDirectoryName": "Files",
  "dxFileManager-errorNoAccess": "Access Denied. Operation could not be completed.",
  "dxFileManager-errorDirectoryExistsFormat": "Directory '{0}' already exists.",
  "dxFileManager-errorFileExistsFormat": "File '{0}' already exists.",
  "dxFileManager-errorFileNotFoundFormat": "File '{0}' not found.",
  "dxFileManager-errorDirectoryNotFoundFormat": "Directory '{0}' not found.",
  "dxFileManager-errorWrongFileExtension": "File extension is not allowed.",
  "dxFileManager-errorMaxFileSizeExceeded": "File size exceeds the maximum allowed size.",
  "dxFileManager-errorInvalidSymbols": "This name contains invalid characters.",
  "dxFileManager-errorDefault": "Unspecified error.",
  "dxFileManager-errorDirectoryOpenFailed": "The directory cannot be opened",

  "dxFileManager-commandCreate": "New directory",
  "dxFileManager-commandRename": "Rename",
  "dxFileManager-commandMove": "Move to",
  "dxFileManager-commandCopy": "Copy to",
  "dxFileManager-commandDelete": "Delete",
  "dxFileManager-commandDownload": "Download",
  "dxFileManager-commandUpload": "Upload files",
  "dxFileManager-commandRefresh": "Refresh",
  "dxFileManager-commandThumbnails": "Thumbnails View",
  "dxFileManager-commandDetails": "Details View",
  "dxFileManager-commandClearSelection": "Clear selection",
  "dxFileManager-commandShowNavPane": "Toggle navigation pane",

  "dxFileManager-dialogDirectoryChooserMoveTitle": "Move to",
  "dxFileManager-dialogDirectoryChooserMoveButtonText": "Move",
  "dxFileManager-dialogDirectoryChooserCopyTitle": "Copy to",
  "dxFileManager-dialogDirectoryChooserCopyButtonText": "Copy",
  "dxFileManager-dialogRenameItemTitle": "Rename",
  "dxFileManager-dialogRenameItemButtonText": "Save",
  "dxFileManager-dialogCreateDirectoryTitle": "New directory",
  "dxFileManager-dialogCreateDirectoryButtonText": "Create",
  "dxFileManager-dialogDeleteItemTitle": "Delete",
  "dxFileManager-dialogDeleteItemButtonText": "Delete",
  "dxFileManager-dialogDeleteItemSingleItemConfirmation": "Are you sure you want to delete {0}?",
  "dxFileManager-dialogDeleteItemMultipleItemsConfirmation": "Are you sure you want to delete {0} items?",
  "dxFileManager-dialogButtonCancel": "Cancel",

  "dxFileManager-editingCreateSingleItemProcessingMessage": "Creating a directory inside {0}",
  "dxFileManager-editingCreateSingleItemSuccessMessage": "Created a directory inside {0}",
  "dxFileManager-editingCreateSingleItemErrorMessage": "Directory was not created",
  "dxFileManager-editingCreateCommonErrorMessage": "Directory was not created",

  "dxFileManager-editingRenameSingleItemProcessingMessage": "Renaming an item inside {0}",
  "dxFileManager-editingRenameSingleItemSuccessMessage": "Renamed an item inside {0}",
  "dxFileManager-editingRenameSingleItemErrorMessage": "Item was not renamed",
  "dxFileManager-editingRenameCommonErrorMessage": "Item was not renamed",

  "dxFileManager-editingDeleteSingleItemProcessingMessage": "Deleting an item from {0}",
  "dxFileManager-editingDeleteMultipleItemsProcessingMessage": "Deleting {0} items from {1}",
  "dxFileManager-editingDeleteSingleItemSuccessMessage": "Deleted an item from {0}",
  "dxFileManager-editingDeleteMultipleItemsSuccessMessage": "Deleted {0} items from {1}",
  "dxFileManager-editingDeleteSingleItemErrorMessage": "Item was not deleted",
  "dxFileManager-editingDeleteMultipleItemsErrorMessage": "{0} items were not deleted",
  "dxFileManager-editingDeleteCommonErrorMessage": "Some items were not deleted",

  "dxFileManager-editingMoveSingleItemProcessingMessage": "Moving an item to {0}",
  "dxFileManager-editingMoveMultipleItemsProcessingMessage": "Moving {0} items to {1}",
  "dxFileManager-editingMoveSingleItemSuccessMessage": "Moved an item to {0}",
  "dxFileManager-editingMoveMultipleItemsSuccessMessage": "Moved {0} items to {1}",
  "dxFileManager-editingMoveSingleItemErrorMessage": "Item was not moved",
  "dxFileManager-editingMoveMultipleItemsErrorMessage": "{0} items were not moved",
  "dxFileManager-editingMoveCommonErrorMessage": "Some items were not moved",

  "dxFileManager-editingCopySingleItemProcessingMessage": "Copying an item to {0}",
  "dxFileManager-editingCopyMultipleItemsProcessingMessage": "Copying {0} items to {1}",
  "dxFileManager-editingCopySingleItemSuccessMessage": "Copied an item to {0}",
  "dxFileManager-editingCopyMultipleItemsSuccessMessage": "Copied {0} items to {1}",
  "dxFileManager-editingCopySingleItemErrorMessage": "Item was not copied",
  "dxFileManager-editingCopyMultipleItemsErrorMessage": "{0} items were not copied",
  "dxFileManager-editingCopyCommonErrorMessage": "Some items were not copied",

  "dxFileManager-editingUploadSingleItemProcessingMessage": "Uploading an item to {0}",
  "dxFileManager-editingUploadMultipleItemsProcessingMessage": "Uploading {0} items to {1}",
  "dxFileManager-editingUploadSingleItemSuccessMessage": "Uploaded an item to {0}",
  "dxFileManager-editingUploadMultipleItemsSuccessMessage": "Uploaded {0} items to {1}",
  "dxFileManager-editingUploadSingleItemErrorMessage": "Item was not uploaded",
  "dxFileManager-editingUploadMultipleItemsErrorMessage": "{0} items were not uploaded",
  "dxFileManager-editingUploadCanceledMessage": "Canceled",

  "dxFileManager-editingDownloadSingleItemErrorMessage": "Item was not downloaded",
  "dxFileManager-editingDownloadMultipleItemsErrorMessage": "{0} items were not downloaded",

  "dxFileManager-listDetailsColumnCaptionName": "Name",
  "dxFileManager-listDetailsColumnCaptionDateModified": "Date Modified",
  "dxFileManager-listDetailsColumnCaptionFileSize": "File Size",

  "dxFileManager-listThumbnailsTooltipTextSize": "Size",
  "dxFileManager-listThumbnailsTooltipTextDateModified": "Date Modified",

  "dxFileManager-notificationProgressPanelTitle": "Progress",
  "dxFileManager-notificationProgressPanelEmptyListText": "No operations",
  "dxFileManager-notificationProgressPanelOperationCanceled": "Canceled",

  "dxDiagram-categoryGeneral": "Geral",
  "dxDiagram-categoryFlowchart": "Fluxograma",
  "dxDiagram-categoryOrgChart": "Organograma",
  "dxDiagram-categoryContainers": "Contêineres",
  "dxDiagram-categoryCustom": "Personalizado",

  "dxDiagram-commandExportToSvg": "Exportar para SVG",
  "dxDiagram-commandExportToPng": "Exportar para PNG",
  "dxDiagram-commandExportToJpg": "Exportar para JPG",
  "dxDiagram-commandUndo": "Desfazer",
  "dxDiagram-commandRedo": "Refazer",
  "dxDiagram-commandFontName": "Nome da fonte",
  "dxDiagram-commandFontSize": "Tamanho da fonte",
  "dxDiagram-commandBold": "Negrito",
  "dxDiagram-commandItalic": "Itálico",
  "dxDiagram-commandUnderline": "Sublinhado",
  "dxDiagram-commandTextColor": "Cor da fonte",
  "dxDiagram-commandLineColor": "Cor da linha",
  "dxDiagram-commandLineWidth": "Espessura da linha",
  "dxDiagram-commandLineStyle": "Estilo da linha",
  "dxDiagram-commandLineStyleSolid": "Sólido",
  "dxDiagram-commandLineStyleDotted": "Pontilhado",
  "dxDiagram-commandLineStyleDashed": "Tracejado",
  "dxDiagram-commandFillColor": "Cor de preenchimento",
  "dxDiagram-commandAlignLeft": "Alinhar à esquerda",
  "dxDiagram-commandAlignCenter": "Centralizar horizontalmente",
  "dxDiagram-commandAlignRight": "Alinhar à direita",
  "dxDiagram-commandConnectorLineType": "Tipo de conexão",
  "dxDiagram-commandConnectorLineStraight": "Reto",
  "dxDiagram-commandConnectorLineOrthogonal": "Ortogonal",
  "dxDiagram-commandConnectorLineStart": "Conector de início de linha",
  "dxDiagram-commandConnectorLineEnd": "Conector de fim de linha",
  "dxDiagram-commandConnectorLineNone": "Nenhum",
  "dxDiagram-commandConnectorLineArrow": "Flecha",
  "dxDiagram-commandFullscreen": "Tela cheia",
  "dxDiagram-commandUnits": "Unidades",
  "dxDiagram-commandPageSize": "Tamanho da página",
  "dxDiagram-commandPageOrientation": "Orientação",
  "dxDiagram-commandPageOrientationLandscape": "Paiagem",
  "dxDiagram-commandPageOrientationPortrait": "Retrato",
  "dxDiagram-commandPageColor": "Cor da página",
  "dxDiagram-commandShowGrid": "Mostrar grade",
  "dxDiagram-commandSnapToGrid": "Ajustar à grade",
  "dxDiagram-commandGridSize": "Tamanho da grade",
  "dxDiagram-commandZoomLevel": "Nível de zoom",
  "dxDiagram-commandAutoZoom": "Zoom automático",
  "dxDiagram-commandFitToContent": "Ajustar ao conteúdo",
  "dxDiagram-commandFitToWidth": "Ajustar à largura",
  "dxDiagram-commandAutoZoomByContent": "Zoom automático por conteúdo",
  "dxDiagram-commandAutoZoomByWidth": "Zoom automático por largura",
  "dxDiagram-commandSimpleView": "Visualização simples",
  "dxDiagram-commandCut": "Cortar",
  "dxDiagram-commandCopy": "Copiar",
  "dxDiagram-commandPaste": "Colar",
  "dxDiagram-commandSelectAll": "Selecionar tudo",
  "dxDiagram-commandDelete": "Remover",
  "dxDiagram-commandBringToFront": "Trazer para a frente",
  "dxDiagram-commandSendToBack": "Enviar para o fundo",
  "dxDiagram-commandLock": "Bloquear",
  "dxDiagram-commandUnlock": "Desbloquear",
  "dxDiagram-commandInsertShapeImage": "Inserir imagem...",
  "dxDiagram-commandEditShapeImage": "Alterar imagem...",
  "dxDiagram-commandDeleteShapeImage": "Remover imagem",
  "dxDiagram-commandLayoutLeftToRight": "Da esquerda para a direita",
  "dxDiagram-commandLayoutRightToLeft": "Da direita para a esquerda",
  "dxDiagram-commandLayoutTopToBottom": "De cima para baixo",
  "dxDiagram-commandLayoutBottomToTop": "De baixo para cima",

  "dxDiagram-unitIn": "in",
  "dxDiagram-unitCm": "cm",
  "dxDiagram-unitPx": "px",

  "dxDiagram-dialogButtonOK": "Aceitar",
  "dxDiagram-dialogButtonCancel": "Cancelar",
  "dxDiagram-dialogInsertShapeImageTitle": "Inserir imagem",
  "dxDiagram-dialogEditShapeImageTitle": "Alterar imagem",
  "dxDiagram-dialogEditShapeImageSelectButton": "Selecionar imagem",
  "dxDiagram-dialogEditShapeImageLabelText": "ou arraste um arquivo aqui",

  "dxDiagram-uiExport": "Exportar",
  "dxDiagram-uiProperties": "Propriedades",
  "dxDiagram-uiSettings": "Configurações",
  "dxDiagram-uiShowToolbox": "Exibir ferramentas",
  "dxDiagram-uiSearch": "Pesquisar",
  "dxDiagram-uiStyle": "Estilo",
  "dxDiagram-uiLayout": "Leiaute",
  "dxDiagram-uiLayoutTree": "Árvores",
  "dxDiagram-uiLayoutLayered": "Níveis",
  "dxDiagram-uiDiagram": "Diagrama",
  "dxDiagram-uiText": "Texto",
  "dxDiagram-uiObject": "Objeto",
  "dxDiagram-uiConnector": "Conector",
  "dxDiagram-uiPage": "Página",

  "dxDiagram-shapeText": "Texto",
  "dxDiagram-shapeRectangle": "Retângulo",
  "dxDiagram-shapeEllipse": "Elipse",
  "dxDiagram-shapeCross": "Cruz",
  "dxDiagram-shapeTriangle": "Triângulo",
  "dxDiagram-shapeDiamond": "Diamante",
  "dxDiagram-shapeHeart": "Coração",
  "dxDiagram-shapePentagon": "Pentágono",
  "dxDiagram-shapeHexagon": "Hexágono",
  "dxDiagram-shapeOctagon": "Octógono",
  "dxDiagram-shapeStar": "Estrela",
  "dxDiagram-shapeArrowLeft": "Flecha à esquerda",
  "dxDiagram-shapeArrowUp": "Flecha para cima",
  "dxDiagram-shapeArrowRight": "Flecha à direita",
  "dxDiagram-shapeArrowDown": "Flecha para baixo",
  "dxDiagram-shapeArrowUpDown": "Flecha para cima e para baixo",
  "dxDiagram-shapeArrowLeftRight": "Flecha à esquerda e direita",
  "dxDiagram-shapeProcess": "Processo",
  "dxDiagram-shapeDecision": "Decisão",
  "dxDiagram-shapeTerminator": "Exterminador",
  "dxDiagram-shapePredefinedProcess": "Processo predefinido",
  "dxDiagram-shapeDocument": "Documento",
  "dxDiagram-shapeMultipleDocuments": "Vários documentos",
  "dxDiagram-shapeManualInput": "Entrada manual",
  "dxDiagram-shapePreparation": "Preparação",
  "dxDiagram-shapeData": "Dados",
  "dxDiagram-shapeDatabase": "Base de dados",
  "dxDiagram-shapeHardDisk": "Disco rígido",
  "dxDiagram-shapeInternalStorage": "Memória interna",
  "dxDiagram-shapePaperTape": "Fita de papel",
  "dxDiagram-shapeManualOperation": "Operação manual",
  "dxDiagram-shapeDelay": "Atraso",
  "dxDiagram-shapeStoredData": "Dados armazenados",
  "dxDiagram-shapeDisplay": "Tela",
  "dxDiagram-shapeMerge": "Fusão",
  "dxDiagram-shapeConnector": "Conector",
  "dxDiagram-shapeOr": "Ou",
  "dxDiagram-shapeSummingJunction": "Junção de soma",
  "dxDiagram-shapeContainerDefaultText": "Contêiner",
  "dxDiagram-shapeVerticalContainer": "Contêiner vertical",
  "dxDiagram-shapeHorizontalContainer": "Contêiner horizontal",
  "dxDiagram-shapeCardDefaultText": "Nome",
  "dxDiagram-shapeCardWithImageOnLeft": "Cartão com imagem à esquerda",
  "dxDiagram-shapeCardWithImageOnTop": "Cartão com imagem na parte superior",
  "dxDiagram-shapeCardWithImageOnRight": "Cartão com imagem à direita",

  "dxGantt-dialogTitle": "Título",
  "dxGantt-dialogStartTitle": "Iniciar",
  "dxGantt-dialogEndTitle": "Encerrar",
  "dxGantt-dialogProgressTitle": "Progresso",
  "dxGantt-dialogResourcesTitle": "Recursos",
  "dxGantt-dialogResourceManagerTitle": "Gerenciador de recursos",
  "dxGantt-dialogTaskDetailsTitle": "Detalhes da etapa",
  "dxGantt-dialogEditResourceListHint": "Editar lista de recursos",
  "dxGantt-dialogEditNoResources": "Sem recursos",
  "dxGantt-dialogButtonAdd": "Adicionar",
  "dxGantt-contextMenuNewTask": "Nova etapa",
  "dxGantt-contextMenuNewSubtask": "Nova subetapa",
  "dxGantt-contextMenuDeleteTask": "Excluir etapa",
  "dxGantt-contextMenuDeleteDependency": "Excluir dependência",
  "dxGantt-dialogTaskDeleteConfirmation": "Excluir uma etapa também exclui suas dependências e subetapa. Você tem certeza que quer excluir essa etapa?",
  "dxGantt-dialogDependencyDeleteConfirmation": "Você tem certeza que quer excluir a dependência desta etapa?",
  "dxGantt-dialogResourcesDeleteConfirmation": "Ao excluir o recurso, ele também será excluido das etapad em que está atribuído. Tem certeza que quer excluir estes recursos? Recurso: {0}",
  "dxGantt-dialogConstraintCriticalViolationMessage": "A etapa a ser movida está ligada a uma outra etapa, por uma relação de dependência. Esta alteração conflita com as regras de dependências. Como você gostaria de proceder?",
  "dxGantt-dialogConstraintViolationMessage": "A etapa a ser movida está ligada a uma outra etapa, por uma relação de dependência. Como você gostaria de proceder?",
  "dxGantt-dialogCancelOperationMessage": "Cancelar operação",
  "dxGantt-dialogDeleteDependencyMessage": "Excluir a dependência",
  "dxGantt-dialogMoveTaskAndKeepDependencyMessage": "Mover a etapa e manter a dependência",
  "dxGantt-dialogConstraintCriticalViolationSeveralTasksMessage": "The task you are attempting to move is linked to another tasks by dependency relations. This change would conflict with dependency rules. How would you like to proceed?",
  "dxGantt-dialogConstraintViolationSeveralTasksMessage": "The task you are attempting to move is linked to another tasks by dependency relations. How would you like to proceed?",
  "dxGantt-dialogDeleteDependenciesMessage": "Delete the dependency relations",
  "dxGantt-dialogMoveTaskAndKeepDependenciesMessage": "Move the task and keep the dependencies",
  "dxGantt-undo": "Desfazer",
  "dxGantt-redo": "Refazer",
  "dxGantt-expandAll": "Expandir tudo",
  "dxGantt-collapseAll": "Contrair tudo",
  "dxGantt-addNewTask": "Nova etapa",
  "dxGantt-deleteSelectedTask": "Excluir etapas selecionadas",
  "dxGantt-zoomIn": "Aumentar zoom",
  "dxGantt-zoomOut": "Diminuir zoom",
  "dxGantt-fullScreen": "Tela cheia",
  "dxGantt-quarter": "Q{0}",
  "dxGantt-sortingAscendingText": "Ordenar ascendentemente",
  "dxGantt-sortingDescendingText": "Ordenar descendentemente",
  "dxGantt-sortingClearText": "Limpar ordenação",
  "dxGantt-showResources": "Show Resources",
  "dxGantt-showDependencies": "Show Dependencies",
  "dxGantt-dialogStartDateValidation": "Start date must be after {0}",
  "dxGantt-dialogEndDateValidation": "End date must be after {0}"
}
