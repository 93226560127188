import { Injectable } from '@angular/core';

import { Auth, User, getAuth } from '@firebase/auth';

import { FirestoreService } from './firestore.service';
import { doc, getDoc } from '@firebase/firestore';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { AsaasClient } from "../models/asaas-client";
import { LogLocationsEnum, LogOperationsEnum } from '../enums/logs.enum';
import { LogModel } from '../models/log-model';
import { LogService } from './log.service';
import { UserModel } from '../models/user-model';
import { Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends FirestoreService {
  private checkMembershipStatusSubject = new Subject<string>();

  protected auth: Auth;

  constructor(
    private apiService: ApiService,
    private logService: LogService
  ) {
    super();
    this.auth = getAuth(FirestoreService.app);
    this.setCollectionRef('users');

    this.checkMembershipStatusSubject.pipe(
      debounceTime(6000),
      switchMap(userId => this.callFirebaseFunction(userId))
    ).subscribe();
  }

  async getAuthorization() {
    const idToken = await this.getIdToken();
    return { Authorization: 'Bearer ' + idToken };
  }

  async getUserSnap(docId?: string) {
    let idUser = docId;

    if (!idUser) {
      idUser = await this.getUid();
      if(!idUser) return null;
    }
    let docRef = doc(this.collectionRef, idUser);

    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const document = docSnap.data();
      return document;
    } else {
      return null;
    }
  }

  async getUserState(): Promise<User | null> {
    return new Promise((resolve) => {
      this.auth.onAuthStateChanged((user) => {
        resolve(user);
      })
    })
  }

  async getUid(): Promise<string | null> {
    const user = await this.getUserState();
    if (!user) return null;
    return user?.uid;
  }

  async checkMembershipStatus(userId: string) {
    this.checkMembershipStatusSubject.next(userId);
  }

  async callFirebaseFunction(userId: string) {
    return await this.apiService.get(environment.apiUrl + "/v1/membership/" + userId);
  }

  async createClientData(clientData: AsaasClient) {
    return await this.apiService.post(environment.apiUrl + "/v1/membership/client", clientData);
  }

  async getIdToken() {
    const user = await this.getUserState();
    if (!user) return null;
    return user.getIdToken(true);
  }

  async getRole() {
    const user = await this.getUserState();
    if (!user) return null;
    const userAdmin = await this.get(user.uid);
    return userAdmin?.role;
  }

  async createUser(user: any, password: string) {

    const params = {
      displayName: user.name,
      email: user.email,
      role: user.role,
      password
    };
    const header = await this.getAuthorization();
    return this.apiService.post(environment.apiUrl + "/v1/admin/user", params, header);
  }

  async deleteUser() {
    const userData = await this.getUserState();
    const logDoc: LogModel = {
      createdBy: userData.uid,
      creationDate: new Date(),
      docId: userData.uid,
      addicionalInfo: `Deleção do usuário '${userData.email}' (id: '${userData.uid}') requisitada`
    }
    this.logService.createLog(LogOperationsEnum.Deleted, LogLocationsEnum.User, logDoc);
    this.addOrUpdateDocument({ id: userData.uid, dateDelete: new Date(), requestDelete: true, emailDelete: false });
  }

  public async getProviders(idManage: string): Promise<UserModel[]> {
    const providers: UserModel[] = [];
    try {
      await this.load({
        orders: [
          {
            direction: 'asc',
            fieldPath: 'name'
          }
        ]
      }).then((users) => {
        for (let user of users) {
          if (user.idManage === idManage || user?.listDefault === true) {
            if (user.role.includes('provider')) {
              providers.push(user);
            }
          }
        }
      });

      return providers;
    } catch (e) {
      console.error("Error@getProviders: ", e);
      throw e;
    }
  }
}
