import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { APP_ROUTES } from './constants/routes.const';
import { MembershipGuard } from "./guards/membership.guard";

const routes: Routes = [
  {
    path: APP_ROUTES.START,
    loadChildren: () => import('./modules/start/start.module').then(m => m.StartModule)
  },
  {
    path: APP_ROUTES.MAIN,
    loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule),
    canActivate: [MembershipGuard]
  },
  {
    path: '',
    redirectTo: APP_ROUTES.MAIN,
    pathMatch: 'full',
  },
  {
    path: 'glossary',
    loadChildren: () => import('./modules/main/home/glossary/glossary.module').then(m => m.GlossaryPageModule)
  },
  {
    path: 'select-work-duplicate',
    loadChildren: () => import('./modules/main/work/components/select-work-duplicate/select-work-duplicate.module').then(m => m.SelectWorkDuplicatePageModule)
  },
  {
    path: 'fast-buy',
    loadChildren: () => import('./modules/main/shop/fast-buy/fast-buy.module').then( m => m.FastBuyPageModule)
  },
  {
    path: 'add-budget',
    loadChildren: () => import('./modules/main/shop/add-budget/add-budget.module').then( m => m.AddBudgetPageModule)
  },
  {
    path: 'return-proposal',
    loadChildren: () => import('./modules/main/provider/return-proposal/return-proposal.module').then( m => m.ReturnProposalPageModule)
  },
  {
    path: 'modal-select-region',
    loadChildren: () => import('./modules/main/provider/modal-select-region/modal-select-region.module').then( m => m.ModalSelectRegionPageModule)
  },
  {
    path: 'confirm-delete-account/:id',
    loadChildren: () => import('./modules/start/confirm-delete-account/confirm-delete-account.module').then( m => m.ConfirmDeleteAccountPageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
