import { Injectable } from '@angular/core';
import { UserModel } from '../models/user-model';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';

import { Params } from '@angular/router';

import 'cordova-plugin-purchase';

// SERVICES
import { WorkService } from './work.service';
import { PlansService } from './plans.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class InAppPurchaseService {
  private user: UserModel;

  static plans: string[] = [];
  static ready = new BehaviorSubject(false);
  static store: CdvPurchase.Store;

  isIos: boolean = false;
  constructor(
    private userService: UsersService,
    private workService: WorkService,
    private plansService: PlansService,
    private platform: Platform
  ) {
    this.init();
  }

  async init() {
    this.platform.ready().then(async () => {
      if (!this.platform.is('capacitor')) { return; }
      this.user = await this.userService.getUserSnap();
      //console.log('this.user', this.user);

      this.isIos = this.platform.is('ios');

      if (InAppPurchaseService.store) return;

      InAppPurchaseService.store = CdvPurchase.store;
      InAppPurchaseService.store.when().approved((p) => { p.verify(); }).verified((p) => { p.finish(); });

      if (this.user) {
        InAppPurchaseService.store.applicationUsername = this.user?.id || '';
      }

      InAppPurchaseService.store.validator = environment.apiUrl + (this.platform.is('ios') ? '/v1/apple/verify' : '/v1/google/verify');

      await this.plansService.load().then(async (plans) => {
        //console.log('plans', plans);

        if (this.isIos) {
          InAppPurchaseService.plans = plans.map((p) => p.iosId);
        } else {
          InAppPurchaseService.plans = plans.map((p) => p.androidId);
          InAppPurchaseService.plans.unshift('plans.appobra');
        }
        for (const id of InAppPurchaseService.plans) {
          //console.log('for (const id of InAppPurchaseService.plans) {', id);

          InAppPurchaseService.store.register({
            id,
            type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
            platform: this.isIos
              ? CdvPurchase.Platform.APPLE_APPSTORE
              : CdvPurchase.Platform.GOOGLE_PLAY,
          });
        }

        InAppPurchaseService.store.initialize();
        InAppPurchaseService.ready.next(true);
      });
    });
  }

  async waitReady() {
    await firstValueFrom(InAppPurchaseService.ready.pipe(filter((r: boolean) => r)));
  }

  async getPlansStore(): Promise<(any)[]> {
    try {
      if (!this.user) {
        this.user = await this.userService.get(await this.userService.getUid());
      }
      await this.waitReady();
      const params: Params = {
        orders: [
          { fieldPath: 'order', direction: 'asc' }
        ]
      }
      const plans = await this.plansService.load(params);
      const treatedPlans: any[] = [];
      const paramsWork: Params = {
        where: [
          {
            value: this.user.idManage,
            condition: "==",
            key: "idManage"
          }]
      }
      const worksData = await this.workService.load(paramsWork);
      let storePlan;

      if (!this.isIos) {
        storePlan = InAppPurchaseService.store.get('plans.appobra');
      }
      //console.log('storePlan', storePlan);

      for (const plan of plans) {
        if (plan.qtd >= (worksData.length || 0)) {
          const storeId = this.isIos ? plan.iosId : plan.androidId;
          let storeOffer;
          if (!this.isIos) {
            storeOffer = storePlan?.offers.find((o) => o.id === storeId);
          } else {
            storeOffer = InAppPurchaseService.store.get(storeId);
          }
          if (!storeOffer) continue;
          const mergedPlan: any = {
            ...plan,
            ...storeOffer,
            price: storeOffer?.pricing?.price || storeOffer?.pricingPhases[0]?.price,
            priceMicros: storeOffer?.pricing?.priceMicros || storeOffer?.pricingPhases[0]?.priceMicros,
            description: plan.description,
            title: plan.title,
          };
          treatedPlans.push(mergedPlan);
        }
      }
      return treatedPlans;
    } catch (error) {
      console.log('getPlansStore > error', error);
    }
  }

  async order(id: string) {
    await this.waitReady();
    const user = await this.userService.get(await this.userService.getUid());
    if (!user) {
      throw 'auth/user-not-found';
    }

    if (this.isIos) {
      await InAppPurchaseService.store.get(id)?.getOffer()?.order();
    } else {
      await InAppPurchaseService.store.get(id.split('@')[0])?.getOffer(id)?.order();
    }
  }

}