export const MESSAGE_CONST = {
    REQUIRED_FIELDS: 'Os campos são obrigatórios',
    TRY_AGAIN: 'Tente novamente',
    COULD_NOT_REGISTER: 'Não foi possivel cadastrar, tente novamente.',
    RECOVER_PASSWORD: 'Informe o email corretamente para recuperar a senha',
    INVALID_FIELD: 'Informações inválidas, revise e tente novamente.',
    DATA_FAIL: 'Não conseguimos carregar seus dados, tente mais tarde.',
    EMPTY_FIELDS: 'Preencha email e senha corretamente',
    OK: 'Ok',
    DATA_NOT_FOUND_SIENGE: 'Seus dados não foram encontrados no Sienge, entre em contato conosco',
    EMAIL_SEND: 'Email enviado, verifique sua caixa de entrada',
    EMAIL_SEND_FAIL: 'Não conseguimos enviar o email, tente mais tarde.',
    EMAIL_INVALID: 'Email inválido, tente novamente ou faça um cadastro!',
    USER_FOUND: 'Usuário encontrado',
    USER_DISABLED: 'Usuário desabilitado para maiores informações. Entre em contato com o nosso suporte <strong>contato@infinitanegocios.com</strong>',
    USER_NOT_DOUND: 'Email não cadastrado, faça seu cadastro agora!',
    WRONG_PASSWORD: 'Email ou senha estão incorretos, tente novamente.',
    TOO_MANY_REQUESTS: 'Excedeu o limite de solicitações para recuperar a senha. Tente mais tarde',
    EMAIL_ALREADY_IN_USE: 'Email já cadastrado, tente recuperar a senha ou outro email.',
    ERROR_HAS_OCCURRED: 'Ocorreu um erro, tente novamente mais tarde',
    WORK_DUPLICATED: 'Obra editada, a nova obra aparecerá na listagem de obras',
    WORK_NOT_DUPLICATED: 'Não foi possível duplicar a obra, tente mais tarde',
    WORK_CREATED: 'Obra criada, a nova obra aparecerá na listagem de obras',
    WORK_NOT_CREATED: 'Não foi possível criar a obra, tente mais tarde',

}

export const TOAST_MSG = {
    DELETE_ACCOUNT_TITLE: 'Você tem certeza?',
    DELETE_ACCOUNT_TEXT: 'Caso você decida prosseguir, todos os seus dados serão apagados dos nossos servidores e você estará impossibilitado de gerenciar as suas obras...',
    DELETED_ACCOUNT_TITLE: 'Conta excluída com sucesso!',
    DELETED_ACCOUNT_TEXT: 'Que pena... Sua conta será excluída de nosso sistema em até 15 dias, caso decida retornar nesse período, basta acessar o app com seu login e senha que vamos reativar sua conta novamente. Até breve!',
    UNDERSTOOD_TEXT: 'Entendido',
    AGREE_TEXT: 'Sim, tenho certeza',
    DISAGREE_TEXT: 'Mudei de ideia',
    DELETE_DATA_TITLE: 'Deseja excluir a informação?',
    DELETE_DATA_TEXT: 'Caso você decida prosseguir, o(s) dado(s) escolhido(s) será excluído(s) permanentemente.',
    DELETE_PROFILE_TITLE: 'Deseja excluir ',
    DELETE_PROFILE_TEXT: 'Caso você decida prosseguir, o perfil será excluído permanentemente.',
    DELETED_DATA_TITLE: 'Conta excluída com sucesso!',
    BACK_LOGIN: 'Voltar para tela de login',
    RESET_SUCCESSFUL  : 'Reset enviado com sucesso!',
    RESET_DESCRIPTION : 'Enviamos as instruções para você recuperar a sua senha! Confira sua caixa de email e após, tente o login novamente.',
    USER_DATA_ERROR: 'Erro ao retornar os dados do usuário.',
    VIEW_DATA_ERROR: 'Erro ao retornar os dados.',
    SAVE_DATA_ERROR: 'Erro ao salvar os dados.',
    SAVE_DATA_OK: 'Dados salvos!',
    NOT_AVAILABLE: 'Função indisponível nessa versão.',
    NOT_AVAILABLE_DESKTOP: 'Função indisponível na versão para desktop, apenas no app.',
    SERVIDOR_ERROR: 'Erro de comunicação com o servidor',
    FILTER_ADD_OK: 'Filtro criado com sucesso!',
    FILTER_ADD_ERROR: 'Erro ao criar filtro.',
    ATTACHMENT_ADD_OK: 'Anexo adicionado com sucesso!',
    ATTACHMENT_ADD_ERROR: 'Erro ao adicionar um anexo.',
    ADD_OK: 'Adicionado com sucesso!',
    DELETE_OK: 'Removido com sucesso!',
    ADD_ERROR: 'Erro ao adicionar as informações!',
    PERMISSIONS_OK: 'Permissão atualizada com sucesso',
    EMAIL_ALREADY_IN_USE_CREATE: 'Email já cadastrado, tente outro email',
    ADD_WORK_OK: 'Obra adicionada com sucesso!',
    ADD_WORK_ERROR: 'Erro ao adicionar as informações da obra!',
    ADD_MARKER_OK: 'Marcadores adicionados com sucesso!',
    ADD_MARKER_ERROR: 'Erro ao adicionar os marcadores!',
    ADD_TASK_OK: 'Etapa adicionada com sucesso!',
    EDIT_TASK_OK: 'Etapa editada com sucesso!',
    EDIT_TASK_ERROR: 'Não foi possível editar a etapa, tente mais tarde',
    EDIT_TASK_STATUS_ERROR: 'Não foi possível editar o status a etapa, tente mais tarde',
    EDIT_TASK_STATUS_OK: 'Status a etapa atualizado',
    EDIT_TASK_PROGRESS: 'Progresso atualizado com sucesso',
    EDIT_TASK_PROGRESS_ERROR: 'Não foi possíve atualizar o Progresso',
    CSV_ERROR: 'Não foi possível abrir o arquivo .csv. Certifique-se de que o aparelho possui um leitor compatível',
    PAYMENT_ERROR: 'Ocorreu um erro ao efetuar o pagamento, tente mais tarde',
    CLIENT_REGISTERED: 'Email já cadastrado!',
    DUPLICATE_CLIENT_REGISTERED: 'Deseja adicionar na sua lista?',
    CLIENT_ADD: 'Sim, adicionar na lista!',
    PROGRESS_INVALID_NUMBER: 'Valor do progresso deve ficar entre 0 e 100',
    VALUE_FORM_TASK_INVALID: 'Valor de quantidade e duração deve ser maior que 0',
    ACCOUNT_CREATE: 'Conta criada com sucesso',
    ACCOUNT_Edit: 'Conta editada com sucesso',
    CONFIRM_DELETE_SHOP: 'Deseja remover esse orçamento?',
    SHOP_FAVORITED: 'Orçamento favoritado',
    SHOP_NOT_FAVORITED: 'Orçamento removido dos favoritos',
    INFORMATION: 'Informação',
    CONFIRM_VALUES: 'Qualquer valor de material não inserido, será considerado o valor nulo. Lembrando que após confirmar, não será possível editar a proposta.',
    ERROR_SEND_PROPOSAL: 'Não foi possível enviar a proposta, tente mais tarde.',
    CLIENT_REGISTERED_FOR_YOU: 'Cliente já cadastrado na sua lista!',
    BUDGET_NOT_FOUND: 'Orçamento não encontrado',
    DELETE_ACCOUNT_SUCCESS: 'Conta excluída com sucesso!',
    DELETE_ACCOUNT_ERROR: 'Não foi possível excluir a conta, tente mais tarde',
}
