import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';

// NATIVE
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

//COMPONENT
import { AppComponent } from './app.component';

//MODULES
import { GraphicsViewPageModule } from 'src/app/components/graphics-view/graphics-view.module';
import { AppRoutingModule } from './app-routing.module';
import { LottieModule } from 'ngx-lottie';

// PLUGINS
import player from 'lottie-web';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';

// CONSTANTS
import { FullCalendarModule } from "@fullcalendar/angular";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';

registerLocaleData(localePt);
export const playerFactory = () => player;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot({
      name: '__appObra',
      storeName: '__appObra',
    }),
    ReactiveFormsModule,
    LottieModule.forRoot({ player: playerFactory }),
    HttpClientModule,
    GraphicsViewPageModule,
    FullCalendarModule,
    BrowserAnimationsModule
  ],
  providers: [
    HTTP,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    InAppBrowser,
    Camera,
    SafariViewController,
    { 
      provide: LOCALE_ID, 
      useValue: 'pt-BR' },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
    },
    OneSignal,
    AppVersion,
  ],
  bootstrap: [AppComponent],
})

export class AppModule { }
