import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { NavController, Platform } from "@ionic/angular";
import { Observable } from "rxjs";
import { APP_ROUTES } from "../constants/routes.const";
import { AuthService } from "../services/auth.service";
import { UsersService } from "../services/users.service";

@Injectable({
  providedIn: 'root'
})
export class MembershipGuard implements CanActivate {
  constructor(
    public navCtrl: NavController,
    public authSrvc: AuthService,
    public platform: Platform,
    public userSrvc: UsersService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve) => {
      this.platform.ready().then(() => {
        this.userSrvc.getUserSnap().then((user) => {
          if (!user) {
            this.navCtrl.navigateRoot('/' + APP_ROUTES.START);
            return resolve(false);
          }
          if(!user.role) {
            this.authSrvc.doLogout().then(() => {
              this.navCtrl.navigateRoot('/' + APP_ROUTES.START);
              resolve(false);
            });
          } else {
            this.userSrvc.checkMembershipStatus(user.idManage).then(() => {
              if(user.requestDelete == true) {
                this.userSrvc.addOrUpdateDocument({ id: user.id, requestDelete: false, emailDelete: false, dateDelete: null});
              }
              resolve(true);
            }).catch(() => {
              this.navCtrl.navigateRoot('/' + APP_ROUTES.START + '/' + APP_ROUTES.MEMBERSHIP);
              resolve(false);
            });
          }
        });
      });
    });
  }
}
