export const environment = {
  production: true,
  apiUrl: "https://southamerica-east1-oappobra.cloudfunctions.net/api",
  clientId: '290784039176-mqjsggi0rg4o8pep44dd1rtjfpit0f7t.apps.googleusercontent.com',
  iosClientId: '290784039176-5tgqos8o9paj02hiqua3i9ulcpmqrj19.apps.googleusercontent.com',
  appleRedirect: 'https://oappobra.firebaseapp.com/__/auth/handler',
  //apiUrl: "http://127.0.0.1:5001/appobra-dev/southamerica-east1/api",
  firebaseConfig: {
    apiKey: "AIzaSyCWDOndYHdFoxei5Pr2FbqUsX8K_GPv_bc",
    authDomain: "oappobra.firebaseapp.com",
    projectId: "oappobra",
    storageBucket: "oappobra.appspot.com",
    messagingSenderId: "290784039176",
    appId: "1:290784039176:web:00cd63824495cbf7841cbd"
  },
  oneSignal: {
    appId: 'f6a3cd2b-eae7-4d57-a243-fb7107cc025a',
    googleProjectNumber: '290784039176'
  },
};