import { Injectable } from '@angular/core';
import { initializeApp } from '@firebase/app';
import { indexedDBLocalPersistence, initializeAuth } from '@firebase/auth';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  protected static app;

  constructor() {
    this.init();
  }

  init() {
    if (!FirebaseService.app) {
      FirebaseService.app = initializeApp(environment.firebaseConfig);
      initializeAuth(FirebaseService.app, {
        persistence: indexedDBLocalPersistence
      });
    }
  }
}
